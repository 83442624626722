@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 5px;

  .country_selected {
    color: map-get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-700;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.25em;

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: 2px;
    }

    &:hover:focus,
    &:focus {
      box-shadow: $box-shadow-focus;
    }
  }

  svg {
    width: $icon-size-xsmall;
    height: $icon-size-xsmall;
  }
}

.dropdown_container {
  position: absolute;
  z-index: 1;
  display: none;
  min-width: 360px;
  height: 300px;
  border: 1px solid map-get($primary, primary20);
  background-color: map-get($base, white100);
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 map-get($base, white10);
  overflow-y: scroll;
  transition: opacity 0.3s;

  li {
    display: flex;
    align-items: center;
    padding-left: 20px;
    border: 1px solid transparent;

    button {
      width: 100%;
      text-align: left;
    }
  }

  li:hover {
    border-color: map-get($primary, primary20);
    background-color: map-get($primary, primary10);
    border-radius: 4px;
  }

  li svg {
    margin-left: -10px;
  }

  &[aria-expanded='true'] {
    display: block;
  }

  &[aria-expanded='false'] {
    display: none;
  }
}
