@use "sass:map";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.mainContent {
  background: linear-gradient(180deg, map.get($primary, primary100) 0%, $contrast-light-blue 41%, $contrast-light-blue-gradient 100%);
}

.fullWidth {
  width: 100%;
}

.joinClassPage {
  background: transparent;

  .header {
    .close {
      display: flex;
      justify-content: flex-end;

      button {
        border: 1px solid $color-light-grey;

        span {
          color: map.get($primary, primary100);
        }

        svg {
          width: 1rem;
          height: 1rem;
          fill: map.get($primary, primary100);
        }

        &:hover {
          background-color: $color-very-light-grey;
        }

        &:focus {
          @include standart-focus-button;
        }
      }
    }

    .headingStyle {
      h2 {
        font-weight: $font-weight-700;
      }

      color: map.get($primary, primary100);
    }
  }

  .notificationView {
    display: flex;
    padding: 3px 5px 5px;
    border: none;
    border-radius: 5px;
    margin: 0.5rem 0;
    margin-right: 30px;
    background-color: $color-panel-background-light;

    span {
      padding-right: 10px;
      vertical-align: middle;
    }
  }

  .contentContainer {
    padding: 1rem;

    .productInfo {
      display: flex;
      align-items: center;
      padding: 1rem;

      .thumbnail {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.75rem;
        margin-right: 0.625rem;
        background-color: map.get($modern, isadora-pink);
        font-size: 1.5rem;
        font-weight: $font-weight-600;
        text-align: center;
      }

      .names {
        .title {
          font-size: 1rem;
          font-weight: $font-weight-600;
          line-height: 1.5rem;
        }

        .organisationContainer {
          display: flex;
          align-items: center;

          .organisationName {
            color: map.get($validation, validation-green100);
            font-size: 0.75rem;
            font-weight: $font-weight-600;
            line-height: 1.125rem;
          }

          .icon {
            width: $icon-size-small;
            height: $icon-size-small;
            fill: map.get($validation, validation-green100);
          }
        }
      }
    }
  }

  .icon {
    display: flex;
    width: 1.2em;
    height: 1.2em;
    box-sizing: border-box;
    align-items: flex-start;
    margin: 5px;
  }

  .link {
    margin-left: 1rem;
  }

  .placementTestCodeInputContainer {
    padding: 1rem;

    .placementTestCodeInputContent {
      .validationMessage {
        margin-top: 1.5rem;
      }
    }

    .header {
      .close {
        display: flex;
        justify-content: flex-end;
      }

      .headingStyle {
        h2 {
          font-weight: $font-weight-700;
        }

        color: map.get($primary, primary100);
      }
    }
  }
}
