@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.addLicenseDetailsContainer {
  position: relative;
  padding: 10px 5px;
}

.createBatchHeader {
  padding: 5px 5px 5px 10px;
  margin-bottom: 10px;
  background-color: map-get($primary, primary100);
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  color: map-get($base, white100);
}

.productTab {
  display: block;
}

.productTabHidden {
  display: none;
}

.licenseContainer {
  padding: 10px;
  border: 1px solid map-get($primary, primary100);
  background-color: map-get($base, white100);
  border-radius: 10px;
}

.licenseBatchSelector {
  display: flex;
  padding: 5px 5px 5px 5px;
  margin-bottom: 20px;
  background-color: map-get($base, white50);
  border-radius: 10px;
  color: map-get($base, white100);
  text-transform: capitalize;

  li {
    display: inline;
  }
}

.licenseAndBatch {
  display: flex;
  width: 260px;
  justify-content: space-between;
}

.license,
.batch {
  display: inline-flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 5px;
  background-color: map-get($primary, primary30);
  border-radius: 5px;
  color: map-get($base, white100);
  cursor: pointer;
}

.tabButton {
  display: inline-flex;
  min-width: 250px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 5px;
  margin-top: 10px;
  margin-right: 20px;
  background-color: map-get($primary, primary30);
  border-radius: 5px;
  color: map-get($base, white100);
  cursor: pointer;
}

.active {
  background-color: map-get($primary, primary100);
  color: map-get($base, white100);
  font-weight: 500;
}

.productDetailsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 35px 0;

  >div:nth-child(2) {
    width: 100%;

    >div:first-child {
      >div:first-child {
        width: 100%;
      }
    }
  }
}

.productRequired {
  display: flex;
  width: 100px;
  align-items: center;
  color: map-get($primary, primary100);
  font-size: 20px;
}

.redStar {
  position: relative;
  top: -6px;
  left: 5%;
  display: inline-flex;
  color: map-get($heritage, ruskin-red100);
  font-size: 25px;
}

.mainContainer {
  padding: 10px 20px;
  border: 1px solid map-get($primary, primary100);
  background-color: map-get($base, white100);
  border-radius: 10px;
}

.articleDiv {
  background-color: inherit;
}

.productDetails,
.licenseDetails {
  position: relative;
  padding: 25px 20px;
  border: 1px solid map-get($primary, primary100);
  margin-bottom: 15px;
  background-color: map-get($base, white100);
  border-radius: 10px;
}

.productDetails > div {
  width: 100%;
  background-color: map-get($base, white100);

  > div:nth-child(2) {
    > div {
      > div {
        max-width: 100%;
        flex-basis: 100%;

        >ol,
        >ol li {
          border: none;
        }

        >ol {
          padding-top: 0;
        }
      }
    }
  }
}

.productSummary,
.licenseTitle {
  position: absolute;
  top: -15px;
  display: inline-block;
  padding: 4px 15px;
  background-color: map-get($primary, primary20);
  border-radius: 40px;
}

.licenseTitle {
  top: -15px;
}

.productSpan {
  position: absolute;
  top: -1%;
  left: 3%;
  display: inline-block;
  padding: 4px 15px;
  background-color: map-get($primary, primary20);
  border-radius: 20px;
}

.productRequired ~ div:nth-child(2) {
  > div:nth-child(2) {
    position: absolute;
    overflow: scroll;
    width: 87%;
    max-height: 490px;
    margin-left: 8%;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  height: max-content;
  align-items: center;

  .names {
    position: relative;
    display: flex;
    width: 250px;
    align-items: flex-start;
    color: map-get($primary, primary100);
    font-size: 15px;

    span {
      font-size: 20px;
    }
  }

  select,
  input {
    width: 100%;
    padding: 5px;
    border: none;
    margin-left: 50px;
    outline: 1px solid map-get($primary, primary50);
  }

  input:focus {
    outline: 1px solid map-get($primary, primary100);
  }
}

.licenseDetails {
  margin-top: 50px;

  .detailsTable {
    text-align: left;
  }
}

.italicI {
  position: absolute;
  display: inline-flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: map-get($primary, primary40);
  border-radius: 5px;
  color: map-get($base, white100);
  cursor: pointer;
  font-family: monospace;
  font-size: 20px;
  font-weight: 900;
}

.saveButton {
  padding: 10px 20px;
  background-color: map-get($primary, primary100);
  border-radius: 4px;
  color: map-get($base, white100);
  text-transform: capitalize;
}

.disabledSaveButton {
  padding: 10px 20px;
  background-color: map-get($primary, primary50);
  border-radius: 4px;
  color: map-get($base, white100);
  text-transform: capitalize;
}

.buttonContainer {
  padding: 20px;
  text-align: right;
}

.calendarContainer {
  position: absolute;
  z-index: 1;
  left: 18%;
  background-color: map-get($primary, primary10);
}

.calendarLabelContainer {
  position: relative;
}

.infoContainer {
  position: absolute;
  z-index: 1;
  top: 52px;
  display: none;
  padding: 20px;
  background-color: map-get($primary, primary100);
  color: map-get($base, white100);

  p {
    width: max-content;
    max-width: 500px;
  }
}

.infoContainerActive {
  display: inline-block;
}

.infoContainer::before {
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid map-get($primary, primary100);
  border-left: 10px solid transparent;
  content: '';
  transform: translateX(-50%);
}

.informationContainer {
  position: relative;
  display: flex;
  width: 100px;
  justify-content: center;
  padding-top: 15px;
}

.errorMessage {
  padding: 10px;
  margin: 8px;
  background-color: map-get($modern, isadora-pink);
  border-radius: 4px;
  text-align: center;
}

.successMessage {
  padding: 10px;
  margin: 8px;
  background-color: map-get($modern, success-green);
  border-radius: 4px;
  text-align: center;
}

.popUp {
  width: 600px;
}

.popUpInner {
  padding: 0 20px 20px 20px;
}

.addPlatformContainer {
  margin-top: 50px;
}

.noPlatformsMessage {
  height: 100px;
  padding-top: 40px;
  text-align: center;
}

.platformButtonsContainer {
  padding-top: 10px;
  text-align: right;
}

.addPlatformButton {
  display: inline-block;
  margin-right: 10px;
}

.cancelPlatformButton {
  display: inline-block;
}

.popUpAlert {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: '#00000080';

  &::before,
  &::after {
    border: none;
    content: none;
  }
}

.popUpAlertInner {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 175px);
  display: block;
  width: 350px;
  height: 150px;
  padding: 15px 25px;
  border: 1px solid black;
  background-color: white;
  border-radius: 5px;
  text-align: center;

  button {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}

.urlButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.formContainer > div {
  margin: 10px 0;
}

.formContainer > div > div {
  width: 80%;
  display: inline-block;
}

.formContainer > div > label {
  width: 20%;
  display: inline-block;
}
