@use "sass:map";
@use "../../styles/constants/breakpoints";
@use "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@use "~@oup/shared-front-end/dist/styles/themes/base/colors";

.filterBlock {
  padding: sizes.$gap_5 0;
  border-top: sizes.$border-width-1 solid map.get(colors.$primary, primary20);

  &.highlightScores > div > div > div {
    flex-basis: 100%;
  }
}

.filterBlock:nth-child(-n+2) {
  border: 0 none;
}

.filterBlock.levelSelector {
  border-bottom: sizes.$border-width-1 solid map.get(colors.$primary, primary20);
}

.filterBlock.hiddenLevel {
  display: none;
}

.radioOptionsInline {
  display: flex;
  flex-direction: row;
  justify-content: center;

  label {
    flex: 1 1 0;

    span::first-letter {
      text-transform: capitalize;
    }
  }

  @media #{breakpoints.$phoneMedia} {
    flex-direction: column;
  }
}

@media #{breakpoints.$smMedia} {
  .filterBlock:nth-child(-n+2) {
    padding-top: 0;
  }

  .levelSelector {
    display: none;
  }
}

@media #{breakpoints.$smTabletMedia} {
  .modalContainer > button {
    width: 100%;
  }

  .levelSelector {
    padding-top: 0
  }
}
