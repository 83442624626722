@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.switchBookContainer {
  top: -47px;
  right: -185px;
  display: flex;
  width: 177px;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-radius: 12px;
  background: map-get($primary-colors, primary);
  gap: 4px;
}

.switchBookChild {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: $font-weight-400;
  line-height: $line-height-120;
}

.switchBookSVG {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  fill: $color-white;
}

.switchBookChild:hover {
  color: $color-white;
}

.switchBookLinkContainer {
  display: flex;
  width: 162px;
  min-height: 40px;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  border-radius: $border-radius-default;
  background: map-get($primary-colors, primary);
  gap: $gap-3;
}
