@use "sass:map";
/* @Cristi: Why are you mixing variables from different sources? */
@import '../../styles/constants/breakpoints';
@import '../../styles/constants/colors';
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.flexTitle {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.backgroundCenter {
  display: flex;
  width: 3.5em;
  min-width: 3.5em;
  height: 3.5em;
  min-height: 3.5em;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 1.6em;
  background: map.get($validation-colors, pending);
  color: map.get($base, white100);
}

.blackText {
  color: map.get($primary, primary100);
}

.accessFormat {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 1em;
}

.linkFormat {
  margin-top: 0;
  cursor: pointer;
  font-size: 2.1875rem;
  text-decoration: none;
}

.userName {
  margin: 1em 0;

  &__workbookTitle {
    margin-top: 0.5em;
    font-size: 1.2em;
  }
}

.lastAccessed {
  font-size: 0.9em;
}

.pupilBadge {
  &__badgeHeader {
    margin: 0;
    font-size: 1.5em;
    font-weight: 100;
  }

  &__badgeSubtitle {
    font-size: 13px;
  }
}

@media #{$smMedia} {
  .userName {
    margin-top: 0;

    &__workbookTitle {
      display: none;
    }
  }
}

@media #{$xsMedia} {
  .backgroundCenter {
    margin-left: 0.7em;
  }

  .flexTitle {
    max-width: 100%;
  }

  .flexTitle .titleFormat {
    max-width: calc(100% - 3.5em);

    .linkFormat {
      font-size: 1.5rem;
      word-break: break-all;
    }

    .subtextFormat {
      font-size: 1rem;
      word-break: break-all;
    }
  }
}
