@use "sass:map";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.refreshBar {
  display: flex;
  max-width: 35rem;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.2rem;
  padding-top: $gap-3;
  padding-bottom: $gap-3;
}

.refreshButton {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: map.get($background-and-border-colors, border);
  border-radius: 5rem;
  margin-right: 2rem;
  color: map.get($background-and-border-colors, background-light-blue);
}

.refreshButton:hover {
  border-color: map.get($hubColors, hub_people_border);
  background-color: $color-button-background3;
  color: map.get($hubColors, product_finder_tab_selected_text);
}

.refreshButton:focus {
  box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map.get($secondary-colors, hub_blue), 0 0 0 4px $color-white;
}

.refreshIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.tickIcon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 50%;
  background-color: $color-white;
}

.tickOrangeIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.tickGreenIcon {
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 50%;
  background-color: $color-white;
}

.refreshText {
  display: flex;
  margin-left: 5px;
}

.greenLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: map.get($hubColors, hub_active_licence);
  color: $color-white;
}

.orangeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  background-color: map.get($validation, dark_orange);
  color: $color-white;
}

.labelRow {
  padding: 1.2rem;
  padding-bottom: 0.4rem;
}

.labelChange {
  margin-right: 0.5rem;
  font-weight: 900;
}

.noSidePadding {
  padding-right: 0;
  padding-left: 0;
}
