@import '../../styles/constants/colors.scss';
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.expiry {
  width: 100%;
  max-width: 600px;
  margin: 1em;
  font-family: $font-family-text-font;
  font-weight: 400;
  line-height: 1.5;
}

.expiry a {
  text-decoration: underline;
}

.popoutPanelIconHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0;
  text-align: center;

  &:first-child {
    margin-top: 3rem;
  }

  svg {
    width: 6rem;
    height: 6rem;
  }
}

.panelHeading {
  max-width: 100%;
  color: map-get($primary, primary100);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
  }

  .subText {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
}
