@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.subMenuContainer {
  width: 100%;
}

.subMenuContainerDisabled {
  cursor: not-allowed;
}

.menuItem {
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  padding: $gap-2, $gap-4;
  padding-top: $gap-2;
  padding-right: $gap-3;
  padding-bottom: $gap-2;
  padding-left: $gap-3;
  margin-bottom: $gap-1;
  background-color: map-get($base, white0);
  border-radius: $border-radius-default;
  color: map-get($primary, primary100);
  cursor: pointer;
  gap: $gap-2;

  .icon {
    fill: map-get($primary, primary100);
  }

  &:hover {
    background-color: map-get($primary, primary10);
  }

  &:focus {
    box-shadow: $box-shadow-focus;
  }

  &:hover:focus {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary10);
  }

  &:active:focus {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary100);
    color: map-get($base, white100);
  }
}

.menuItemDisabled {
  opacity: 50%;
  pointer-events: none;
}

.menuItem:active,
.menuItemExtended {
  background-color: map-get($primary, primary100);
  color: map-get($base, white100);

  .icon {
    fill: map-get($base, white100);
  }
}

.menuItem:active:hover,
.menuItemExtended:hover {
  background-color: map-get($primary, primary90);
  color: map-get($base, white100);
}

.menuItem:active:hover:focus,
.menuItemExtended:focus:hover {
  box-shadow: $box-shadow-focus;

  background-color: map-get($primary, primary90);
  color: map-get($base, white100);
}

.caratIcon {
  width: 14.96px;
  height: 8.93px;
  padding-left: $gap-2;
  margin-right: 12.5px;
}

.caratIconReverse {
  padding-right: $gap-2;
  padding-left: 0;
  transform: rotate(180deg) scaleX(1);
}

.icon {
  width: $icon-size-regular;
  height: $icon-size-regular;
}
