@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

.container {
  margin-top: -16px;

  a {
    display: inline;
    align-items: center;
    color: map-get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-700;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: max(1px, pxtoem(1px));
    text-underline-offset: 0.25em;

    &:visited {
      text-decoration-color: map-get($primary, primary70);
    }

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: max(2px, pxtoem(2px));
    }

    &:hover:focus,
    &:focus {
      outline: 2px solid map-get($primary, primary100);
      outline-offset: 1px;
    }

    &[disabled] {
      // cursor: default; // This style is moot because it has no effect when pointer-events:none
      color: $color-sub-text-50;
      pointer-events: none;
    }

    svg {
      display: inline-block;
      width: $icon-size-small;
      height: $icon-size-small;
      vertical-align: middle;
    }
  }

  .privacy a {
    svg {
      display: inline-block;
      width: $icon-size-small;
      height: $icon-size-small;
        margin-left: remtoem($gap-1);
      vertical-align: middle;
    }
  }

  .privacyEditForm a {
    position: relative;

    svg {
      width: $icon-size-small;
      height: $icon-size-small;
    }

    &:focus {
      @include no-padding-button-on-focus() ;
    }
  }

  .privacyEditForm a::after {
    position: absolute;
    width: 20px;
    height: 100%;
    margin-right: -20px;
    margin-left: 8px;
  }

  .personalInformationHeader {
    font-weight: 900;
  }

  .personalInformationContainer {
    margin-bottom: 10px;
  }

  .infoIcon {
    display: flex;
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1.2em;
  }

  .marketingPreferencesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mpTooltip {
    margin-top: 8px;
    white-space: nowrap;
  }

  .terms {
    display: flex;
    align-items: flex-start;

    .checkbox {
      margin-top: 5px;
    }
  }
}
