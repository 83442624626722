@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.container {
  text-align: center;
  width: 100%;

  .header {
    margin-bottom: $gap-5;

    h3 {
      font-size: $font-size-medium;
    }

    p {
      margin-bottom: $gap-5;
      max-width: 500px;
      margin: 0 auto;
      font-size: $font-size-medium;
    }
}

  button {
    display: inline-block;
    min-width: 150px;
  }
}

.containerRegistrationForm {
  .header {
    display: flex;
    align-items: baseline;
    column-gap: 20px;

    h3,
    p {
      font-size: $font-size-medium;
      display: inline;
    }
  }

  .pageSubtitle {
    padding-bottom: 16px;
    font-weight: bold;
    color: gray;
  }
}
