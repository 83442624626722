/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */
@import "../constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

:global {
  // Prevents the page from growing beyond the viewport when it's contained in an iframe in iOS
  body {
    max-width: 100vw;

    #maincontent-loading {
      display: -webkit-box;
      display: flexbox;
      display: flex;
      height: var(--vh100, 100vh);
      min-height: var(--vh100, 100vh);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-box-pack: center;
      -ms-flex-align: center;
      -ms-flex-pack: center;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    #loading-spinner {
      display: -webkit-box;
      display: flexbox;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 1rem 0;
      -webkit-box-align: center;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -ms-flex-align: center;
      text-align: center;
    }

    #loading-spinner svg {
      width: 6rem;
      height: 6rem;
    }

    #loading-icon {
      animation: spin 1s linear infinite;
      fill: map-get($hubColors, product_finder_tab_unselected_text);
    }
  }

  // Content opening for ORB
  body[data-theme="orb-theme"] {
    &.content-open {
      overflow: hidden;
    }
  }

  body[data-theme="hub-theme"] {
    max-width: 100%;

    #mnt {
      overflow: unset;
      height: unset;
      min-height: 100vh;
      overflow-y: scroll;
    }
  }

  body[data-theme="rvs-theme"] {
    max-width: 100%;

    #mnt {
      overflow: unset;
      min-height: 100vh;
    }
  }

  body[data-theme="ocp-theme"] {
    // overflow: hidden;
    max-width: 100%;

    #mnt {
      overflow: unset;
      min-height: 100vh;
    }
  }

  .mobile #mnt {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: var(--vh100, 100vh);
  }

  // BODY: This class is applied to <body> to disable page scrolling while panel is open:
  .preventBodyScroll {
    overflow: hidden;
  }

  .example-appear {
    opacity: 0.01;
    transition: opacity 5000ms;
  }

  // Animation classes for `CSSTransition`.
  .transition-group {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
  }

  .transition-group-push {
    animation: push 0.5s;
  }

  .transition-group-pull {
    animation: pull 0.5s;
  }

  // Error style
  .error-paragraph {
    padding: 7px 15px;
    border-radius: 4px;
    margin: 1em;
    background: map-get($validation-colors, error);
    color: $color-white;
  }

  // Screen readers text
  .srOnly {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  @media only screen and (device-width <= 480px), only screen and (width <= 1024px) {
    .horizantal-scroll-mobile {
      overflow-x: auto;
    }
  }
}

@keyframes pull {
  0% {
    width: 200%;
    flex-direction: row-reverse;
    transform: translateX(0%);
  }

  100% {
    width: 200%;
    flex-direction: row-reverse;
    transform: translateX(-50%);
  }
}

@keyframes push {
  0% {
    width: 200%;
    transform: translateX(-50%);
  }

  100% {
    width: 200%;
    transform: translateX(0%);
  }
}
