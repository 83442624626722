@use "sass:map";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

.consentHeader {
  padding: 40px 80px 10px 20px;
}

.consentContainerInput {
  padding-top: 10px;
  padding-right: 10px;
}

.consentContainerHeader {
  display: inline;
  padding-right: 5px;
  font-weight: 900;
}

.consentContainerInputsContainer {
  padding: 20px;
}

.consentContainerHeaderDescription {
  display: inline;
  font-style: italic;
}

.consentModalBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.consentMainBody {
  height: 65%;
  flex-direction: column;
  border-width: 1px;
  border-color: $color-borders;
  border-top-style: solid;
  border-bottom-style: solid;
  overflow-y: scroll;
}

@media screen and (max-width: $screen-width-600) {
    .blockMainBody{
      height: 100%;
      padding-bottom: 20px;
      overflow-y: visible;
    }

}


.consentStatementPersonalInformationHeader {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 900;
}

.consentStatementPersonalInformationText {
  margin: 0 20px;
  font-size: 12px;
  font-weight: 100;

  a {
    margin: 0 5px;
  }
}

.marketingPrefContainer {
  padding-left: 20px;

  input[type="checkbox"] {
    accent-color: map.get($secondary-colors, dashboard);
  }
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;

  svg {
    display: inline-block;
    margin-left: 5px;
  }
}

.consentOptionsContainer {
  padding-top: 15px;

  input[type="checkbox"] {
    accent-color: map.get($secondary-colors, dashboard);
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  a {
    display: inline;
    margin: 0 5px;
    color: map.get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-700;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: max(1px, pxtoem(1px));
    text-underline-offset: 0.25em;

    &:visited {
      text-decoration-color: map.get($primary, primary70);
    }

    &:hover {
      background-color: map.get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: max(2px, pxtoem(2px));
    }

    &:hover:focus,
    &:focus {
      outline: 2px solid map.get($primary, primary100);
      outline-offset: 1px;
    }

    svg {
      display: inline-block;
      width: remtoem($icon-size-xsmall);
      height: remtoem($icon-size-xsmall);
      margin-right: remtoem($gap-1);
      vertical-align: 0;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .marketingPreferencesConsentCheckBox {
    p {
      color: black;
    }
  }
}

.dropdownSearchContainer > div{
  margin-bottom: 20px;
  transform: none;
}
