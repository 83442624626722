@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

.fileUploadInput {
  padding: 1.5rem;
  border: 1px dashed map-get($legacy-color-names, tooltip);
  background: map-get($rvsColors, rvs_drop_zone);
  border-radius: 4px;
  text-align: center;

  // This smoothly transitions in the file hover state
  transition: all 0.25s ease-in-out;

  p {
    overflow: hidden;
    margin: auto;
    margin-bottom: 0.5rem;
    overflow-wrap: break-word;

    &:first-of-type {
    font-weight: bold;
    font-size: 1.7rem;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }
  }

  @media only screen and (max-device-width: 480px),
    only screen and (max-width: 1024px) {
    p {
      width: auto;
      float: none;
      text-align: center;
    }
  }

  // Shown when the user is holding a file over the component
  &.fileHovering {
    border-color: $color-black;
    background: map-get($legacy-color-names, default);
  }

  // Shown when a file has been dropped
  &.fileSelected {
    border-style: solid;
  }

  // Text only button shown to unselect a file or go back from an error
  .backButton {
    padding: 0.5rem;
    text-decoration: underline;
  }

  // Give the label wrapped button a point cursor and diable the button
  label {
    display: inline-block;
    cursor: pointer;

    button {
      // Disable the button so that the label is triggered
      pointer-events: none;
    }

    &:hover button {
      border-color: map-get($primary, primary100);
      background-color: map-get($primary, primary10);
    }

    &:focus button {
      border-color: map-get($primary, primary20);
      background-color: map-get($base, white0);

      box-shadow: $box-shadow-focus;
    }
  }

  button {
    // Center it all buttons
    margin: 0 auto;
  }
}

.certificateUploadError {
  p:nth-child(1) {
    margin-bottom: 0;
    letter-spacing: -0.02em;
  }

  p:nth-child(2) {
    margin-top: 0 !important;
    margin-bottom: 0;
    font-size: 24px;
    letter-spacing: -0.02em;
  }

  button {
    height: 24px !important;
    padding: 0 !important;
    border: 0 !important;
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    background: transparent !important;
    text-decoration: underline !important;
  }
}
