@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.container {
  display: flex;
  width: 375px;
  height: calc(100vh - 64px);
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 0;
  background-color: map.get($base, white100);
  box-shadow: 13px 10px 19px map.get($base, white20);
  color: map.get($primary, primary100);
  overflow-y: scroll;

  h2 {
    margin: 0;
    color: map.get($primary, primary100);
    font-size: $icon-size-regular;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.017em;
    line-height: 150%;
  }

  h3 {
    margin: 0 0 15px;
    color: map.get($primary, primary100);
    font-size: $icon-size-small;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.017em;
    line-height: 150%;
  }

  li {
    color: map.get($primary, primary100);
    font-size: $icon-size-small;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.017em;
    line-height: 150%;
  }
}

@media screen and (max-width: $screen-width-600) {
  .container {
    width: 100vw;
    height: 100vh;
    padding-bottom: 75px;
  }
}

.subMenuContainer {
  width: 100%;
  padding-left: $gap-4;
}

.menuItem {
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  padding: $gap-2 $gap-4 $gap-2 $gap-4;
  border-radius: $border-radius-default;
  margin-bottom: $gap-1;
  background-color: map.get($base, white0);
  color: map.get($primary, primary100);
  cursor: pointer;
  gap: $gap-2;

  .icon {
    fill: map.get($primary, primary100);
  }

  &:hover {
    background-color: map.get($primary, primary10);
  }

  &:focus {
    box-shadow: $box-shadow-focus;
  }

  &:hover:focus {
    box-shadow: $box-shadow-focus;

    background-color: map.get($primary, primary10);
  }

  &:active:focus {
    box-shadow: $box-shadow-focus;

    background-color: map.get($primary, primary100);
    color: map.get($base, white100);
  }
}

.categoryItem {
  justify-content: space-between;
}

.menuItem:active,
.menuItemExtended {
  background-color: map.get($primary, primary100);
  color: map.get($base, white100);

  .icon {
    fill: map.get($base, white100);
  }
}

.menuItem:active:hover,
.menuItemExtended:hover {
  background-color: map.get($primary, primary90);
  color: map.get($base, white100);
}

.menuItem:active:hover:focus,
.menuItemExtended:focus:hover {
  box-shadow: $box-shadow-focus;

  background-color: map.get($primary, primary90);
  color: map.get($base, white100);
}

.icon {
  width: $icon-size-regular;
  height: $icon-size-regular;
}

.caratIcon {
  display: flex;
  width: $icon-size-regular;
  height: $icon-size-regular;
  align-items: center;
  padding-left: $gap-2;
}

.caratIconReverse {
  padding-right: $gap-2;
  padding-left: 0;
  transform: rotate(180deg) scaleX(1);
}

.titleContainer {
  width: 100%;
}

.cpCloseButton {
  width: $icon-size-large;
  height: $icon-size-large;
  padding: 0;
  margin-left: auto;
  color: map.get($primary, primary100);
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

.fileLevelMenuWrapper {
  padding-left: $gap-4;
}

.sections {
  margin-top: 8px;
  margin-bottom: 8px;
  color: map.get($primary, white10);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
