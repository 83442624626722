@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.cpNavRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: map-get($cptColors, background);
}

.cpNavRowText {
  height: 1rem;
  gap: $gap-2;
  line-height: $line-height-125;
}

.cpNavProgress {
  width: 90%;
  min-width: 0;
  margin-right: $gap-3;
  margin-left: $gap-3;
}

.cpNavProgressTitle {
  display: flex;
  max-height: 60px;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: $font-weight-400;
  line-height: 150%;
  text-align: center;
}

.cpActivityTitle {
  overflow: hidden;
  height: 20px;
  color: map-get($modern, kipper-yellow);
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cpNextPrevTitle {
  display: block;
  overflow: hidden;
  width: 33.33%;
  max-height: 1.25rem;
  -webkit-box-orient: vertical;
  color: map-get($base, white100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  -webkit-line-clamp: 2;
  line-height: $line-height-125;
  text-align: $text-align-left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nextTitle {
  text-align: right;
}

.cpNextPrevButton {
  display: flex;
  width: $icon-size-large;
  height: $icon-size-medium;
  max-height: $icon-size-medium;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid map-get($cptColors, light_blue2);
  border-radius: 10px;
  fill: map-get($cptColors, white);
  line-height: 2.9rem;

  &.progressBarNavigationhide {
    visibility: hidden;
  }

  svg {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &:hover {
    background-color: map-get($primary, primary90);
  }

  &:focus {
    box-shadow: $box-shadow-focus;
  }
}

.cpNavRowBullet {
  height: 0.5rem;
  justify-content: space-between;
  margin-top: $gap-2;
  gap: $gap-2;
  line-height: 0.5rem;
}

.cpNavRowBarActive {
  width: 90%;
  height: 0.5rem;
  align-self: center;
  background-color: map-get($modern, kipper-yellow);
  border-radius: 5px;
}

.cpNavRowBarInactive {
  width: 90%;
  height: 0.25rem;
  align-self: center;
  background-color: map-get($cptColors, grey);
  border-radius: 5px;
}

.cpDisabled {
  background-color: map-get($primary, primary40);
  cursor: not-allowed;

  &:hover {
    background-color: map-get($primary, primary40);
  }
}

@media screen and (max-width: 850px) {
  .cpNextPrevTitle {
    display: none;
  }

  .cpNextPrevButton {
    margin: 0;
  }

  .cpNavProgressTitle {
    width: 100%;
    text-overflow: ellipsis;
  }

  .cpNavProgress {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .cpNavRowBullet {
    margin-right: $gap-4;
    margin-left: $gap-4;
  }
}

.cpCloseDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: $gap-2;
}

.cpClose {
  width: $icon-size-large;
  height: $icon-size-medium;
  padding: 0;
  border: 1px solid map-get($cptColors, light_blue2);
  margin: 0;
  background-color: map-get($cptColors, background);
  border-radius: 10px;
  color: map-get($cptColors, white);
  line-height: 2.4rem;
  text-align: center;

  svg {
    width: $icon-size-small;
    height: $icon-size-small;
    fill: map-get($cptColors, white);
  }
}
