@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.dropdownPanel {
  z-index: -1;
  right: 0;
  left: unset;
  opacity: 0;
  pointer-events: none;
  transition: none;

  &.opened {
    z-index: 102;
    margin-top: 0;
    opacity: 1;
    pointer-events: unset;
    transition: none;

    &::before,
    &::after {
      display: none;
    }
  }
}

.panelContainer {
  border-radius: 5px;
  background-color: $color-white;
  box-shadow: 0 0 5px 0 $contrast-light-accent-50;
  cursor: pointer;

  .menuItem {
    display: flex;
    height: 3.125rem;
    color: map-get($typography-colors, text);
    font-size: 1rem;
    line-height: 3.125rem;
    white-space: nowrap;

    button,
    a {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 1rem;
      text-align: left;

      &:focus {
        @include standart-focus-button();

        border-radius: 3px;
        background-color: map-get($secondary-colors, hub_light_grey);
        outline: none;
      }
    }

    &:hover,
    &:focus {
      background-color: map-get($secondary-colors, hub_light_grey);
    }

    &:focus {
      border-radius: 3px;
      box-shadow: inset 0 0 0 2px map-get($primary-colors, primary), 0 0 0 1px $color-white;
    }

    &:not(:first-child):last-of-type {
      button,
      a {
        border-top: 1px solid map-get($background-and-border-colors, border);
      }
    }

    &:first-of-type:hover {
      border-radius: 5px 5px 0 0;
    }

    &:last-of-type:hover {
      border-radius: 0 0 5px 5px;
    }

    .svgContainer {
      display: flex;
      min-width: 3.125rem;
      align-items: center;
      justify-content: center;
      line-height: 3.125rem;

      svg {
        max-width: 1.5625rem;
        max-height: 1.25rem;
      }
    }

    &.menuItemWithSubtitle button {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem;

      span:nth-of-type(2) {
        color: map-get($hubColors, dark-gray);
        font-size: $font-size-xsmall;
      }
    }
  }
}

.dropdownTop {
  bottom: 3.5rem;
}

.dropdownTopClassAssessmentTests {
  bottom: 4rem;
}

.dropdownTopPlacementTests {
  bottom: calc(50% + 1rem);
}
