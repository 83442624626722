@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.breadcrumbs {
  margin-top: 10px;
  min-height: 28px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .chevron {
    height: 10px;
    margin: 0 10px;
    flex: 0 0 10px;
  }

  .level {
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
  }

  .link {
    color: map-get($primary, primary100);
  }

  .linkActive {
    color: map-get($primary, primary100);
    font-weight: bolder;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    color: $color-sub-text-50;
    fill: $color-sub-text-50;
  }
}
