@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.supportArticle {
  p {
    margin-bottom: 25px;
  }

  ol,
  ul {
    padding: 0 1rem;
    padding-left: 40px;
    margin-bottom: 25px;
  }

  ol li {
    display: list-item;
    list-style-type: decimal;
  }

  ul li {
    display: list-item;
    list-style-type: circle;
  }

  img {
    padding: 10px 0 10px 0;
  }

  a {
    display: inline-block;
    color: map-get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-400;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.25em;

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: 2px;
    }

    &:hover:focus,
    &:focus {
      box-shadow: $box-shadow-focus;
    }

    &[disabled] {
      // cursor: default; // This style is moot because it has no effect when pointer-events:none
      color: $color-sub-text-50;
      pointer-events: none;
    }
  }
}

body[data-theme="hub-theme"] {
  .supportArticle {
    p {
      margin-bottom: 0;
    }
  }
}
