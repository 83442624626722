@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.closeToolbar {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
  text-align: right;
}

.scrollPanel {
  height: 100%;
}

@media screen and (max-width: $screen-width-600) {
  .scrollPanel {
    position: relative;
    height: 100%;
    padding-bottom: 15px;
    overflow-y: scroll;
  }
}
