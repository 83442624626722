/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

// Global typography settings
// Based on the clients Style Guide (v3.1)
// http://design.oupnmv.com/UniversalEnrolment/html/style-guide.html
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import '../constants/colors';

body {
  // Default global text and icon colour
  color: $color-text;
  fill: $color-text;
  font-family: $font-family-text-font;
}

// Heading Styles
h1 {
  font-size: 2.1875rem;
  font-weight: 600;
}

h2 {
  font-size: 1.7rem;
  font-weight: 600;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}
/* stylelint-disable selector-type-no-unknown */
h1:not(first),
/* stylelint-disable selector-type-no-unknown */
h2:not(first),
/* stylelint-disable selector-type-no-unknown */
h3:not(first),
/* stylelint-disable selector-type-no-unknown */
h4:not(first) {
  margin-top: 0.8em;
}

h1:not(last),
/* stylelint-disable selector-type-no-unknown */
h2:not(last),
/* stylelint-disable selector-type-no-unknown */
h3:not(last),
/* stylelint-disable selector-type-no-unknown */
h4:not(last) {
  margin-bottom: 0.3em;
}

// Paragraph variant Styles
:global(p.lead) {
  color: $color-sub-text;
  font-size: 1.3rem;
  font-weight: 400;
}
// Paragraph variant Styles
:global(p.lead-bold) {
  color: $color-text;
  font-size: 1.3rem;
  font-weight: 600;
}

p {
  font-size: 1rem;

  @media print {
    font-size: 14px;
  }
}

p + p {
  margin-top: 0.5em;
}

:global(p.sub-text) {
  font-size: 0.85rem;
  font-weight: 400;
}

// Text List styles
:global(ul.text),
:global(ol.text) {
  padding-left: 0.5rem;

  li {
    display: list-item;
    margin-bottom: 0.5rem;
    margin-left: 1.2em;

    &:first-of-type {
      margin-top: 0.5rem;
    }
  }
}

// Alignment:
:global(.text-right) {
  text-align: right;
}

:global(.text-center) {
  text-align: center;
}

// Base Link styling
a {
  color: $color-primary-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-primary-blue-dark;
    text-decoration: underline;
  }

  &[disabled] {
    // cursor: default; // This style is moot because it has no effect when pointer-events:none
    color: $color-sub-text-50;
    pointer-events: none;
  }
}

li a {
  line-height: 1.2rem;
}

// Data List styling
dt {
  margin: 1rem 0 0.25rem;
  font-weight: 600;
}

dd {
  color: $color-sub-text;
}

// To force display:block on non-block element:
// Eg: For a <p><span class="block">...</span></p> (because <div> is forbidden inside <p>)
:global(.block) {
  display: block;
}

// IE Placeholder color must be global
:-ms-input-placeholder {
  color: $color-sub-text-50;
}
