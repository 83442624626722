@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.ProductIcon {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  background-color: map.get($primary, 'primary100');
}

.StatusIcon {
  width: 26px;
  height: 26px;
}
