@use "sass:map";
@import "../../../../styles/constants/colors";
@import "../../../../styles/mixins/hub/hub-button-focus";
@import "../../../../styles/constants/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.outerModal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in background-color;
  visibility: hidden;

  table tr td {
    width: 20px !important;
  }

  &.open {
    z-index: 5000;
    background-color: $contrast-light-hover;
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
    visibility: initial;
  }

  .innerModal {
    width: 80%;
    min-height: 20vh;
    padding: $gap-5 $gap-3;
    border-radius: 0.3125rem;
    background-color: $color-white;
    text-align: center;

    .modalContent {
      width: 100%;
      max-height: calc(100vh - 170px);
      overflow-y: auto;

      > div > div {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .modalButtonBottom {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 1rem;
      border-top: 1px solid map.get($secondary-colors, hub_light_grey);
      background-color: $color-white;

      button:first-child {
        padding: 0 10px;
        margin: 0 15px;
      }
    }

    .text {
      color: map.get($primary-colors, primary);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.017em;
      line-height: 24px;
    }

    .boldText {
      color: map.get($primary-colors, primary);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.272px;
      line-height: 150%;
    }

    .modalContentWrapperSuccess {
      display: flex;
      flex-direction: column;
      gap: $gap-6;

      .closeButtonWrapper {
        display: flex;
        min-height: 50px;
        align-items: center;
        justify-content: center;
      }
    }

    .modalContentWrapper {
      display: flex;
      min-height: 50%;
      flex-direction: column;

      hr {
        margin: $gap-5;
      }

      .courseTitle {
        color: map.get($primary-colors, primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.272px;
        line-height: 150%;
      }

      .rowContainer {
        display: flex;
        justify-content: space-between;
        padding: $gap-2 $gap-4;

        .caratIcon {
          display: flex;
          width: $icon-size-regular;
          height: $icon-size-regular;
          align-items: center;
          padding-left: $gap-2;
          cursor: pointer;
        }

        .caratIconReverse {
          display: flex;
          width: $icon-size-regular;
          height: $icon-size-regular;
          align-items: center;
          padding-right: $gap-2;
          cursor: pointer;
          transform: rotate(180deg) scaleX(1);
        }

        .checkboxTitleContainer {
          display: flex;
          justify-content: flex-start;
          gap: $gap-2
        }
      }

      .child {
        display: flex;
        flex-direction: column;
        margin-left: $gap-4;
        gap: $gap-1;
      }

      .notesWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: $gap-5 $gap-4;

        .text {
          align-self: flex-start;
        }

        .notesTextarea {
          height: 172px;
          padding: $gap-4;
          border-color: #b3bdc8;
          border-radius: 10px;
        }
      }
    }
  }
}
