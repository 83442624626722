@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

iframe {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
}

.title {
  color: map-get($cptColors, white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.017em;
  line-height: 150%;
}

.cpMenuItem {
  display: flex;
  min-height: 30px;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  padding: $gap-2 $gap-1;
  margin-top: $gap-1;
  color: map-get($cptColors, white);
  cursor: pointer;
  font-family: $font-family-text-font;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-center;
  text-decoration: none;
}

.cpMenuIcon {
  display: flex;
  width: $icon-size-regular;
  height: $icon-size-regular;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: $gap-1 $gap-3;
  border-radius: $border-radius-xlarge;
  margin-bottom: $gap-1;
}


.cpMenuItem:hover .cpMenuIcon {
  background-color: map-get($primary, primary90);
}

.cpMenuItem:focus .cpMenuIcon {
  box-shadow: $box-shadow-focus;

  transition: background-color 300ms, box-shadow 300ms, color 300ms;
}

.cpMenuItem:active .cpMenuIcon {
  background-color: map-get($cptColors, white);
  fill: map-get($cptColors, dark_blue);
}

.cpMenuItem:active .cpMenuIcon svg {
  fill: map-get($cptColors, background);
}

.cpMenuItem:hover .disabledButton,
.cpMenuItem:active .disabledButton {
  background-color: map-get($primary, primary100);
}

.cpMenuItem:active .disabledButton svg {
  fill: map-get($base, white100);
}

.cpDesktopView {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-color: map-get($cptColors, background);
}

.cpModal {
  display: none;
}

.bookImage {
  max-width: 90%;
  max-height: 100%;
  align-self: center;
}

.cpClose {
  width: 38px;
  height: 38px;
  padding-top: 3px;
  padding-left: 10px;
  border: 1px solid map-get($cptColors, light_blue2);
  border-radius: 10px;
  margin: 0;
  background-color: map-get($cptColors, background);
  color: map-get($cptColors, white);

  svg {
    width: 17px;
    height: 31px;
    margin-left: 0;
    fill: white;
  }
}

.cpDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.header {
  display: flex;
  height: 74px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $gap-4;
}

.cpCloseWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

.cpCenterWrapper,
.mobilePrintButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gap-2 $gap-1;
    color: map-get($base, white100);
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;
    text-align: center;

    p {
      font-family: $font-family-text-font;
      font-size: $font-size-xsmall;
      font-weight: $font-weight-400;
    }

    div {
      padding: $gap-1 $gap-3;
      margin: 0 $gap-4;

      svg {
        width: $icon-size-regular;
        height: $icon-size-regular;
      }
    }
  }
}

.contentContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonIcon, .disabledButton {
  display: flex;
  justify-content: center;
  margin-right: 0;
  fill: map-get($base, white100);
}

.imageIframeSmall {
  display: block;
  width: 45%;
  height: 95%;
  margin: 0 auto;
}

.imageIframeBig {
  display: block;
  width: 95%;
  height: 95%;
  margin: 0 auto;
}

.leftButtonWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.rightButtonWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-left: 50px;

  svg {
    width: 100%;
    height: 100%;
  }
}

@media #{$lgMediaMax}
{
  .cpDesktopView {
    display: none;
  }

  .header {
    padding: 0;
  }

  .title {
    margin-left: 50px;
  }

  .cpMobileView {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background-color: map-get($cptColors, background);
  }

  .cpCloseButton {
    width: 38px;
    height: 38px;
    padding-top: 3px;
    padding-left: 10px;
    border: 1px solid map-get($cptColors, light_blue2);
    border-radius: 10px;
    margin: 0;
    background-color: map-get($cptColors, background);
    color: map-get($cptColors, white);

    svg {
      width: 17px;
      height: 31px;
      margin-left: 0;
      fill: white;
    }
  }

  .cpModalContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .cpModal {
    position: fixed;
    display: flex;
    background-color: map-get($cptColors, background);
    inset: 0;
  }

  .cpModalFooter {
    margin-top: auto;
  }

  .imageContainer {
    display: none;
  }

  .imageContainerMobile {
    display: flex;
    width: 100%;
    max-height: 80%;
    flex: 1;
    flex-direction: row;
    align-items: end;
    justify-content: center;
  }

  .imageContainerMobileSpread {
    display: flex;
    height: auto;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .imageContainerMobileSpread img {
    width: 90vh;
    max-width: 120%;
    object-fit: contain;
    transform: rotate(90deg);
  }

  .bookImage {
    max-width: 90%;
    max-height: 100%;
    align-self: center;
    object-fit: contain;
  }
}

.toggleViewContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.printViewCloseButtonContainer {
  display: flex;
  align-items: flex-end;
}

@media #{$lgMediaMax}

{
  .cpCloseWrapper {
    width: 100%;
  }
}

.mobilePrintButtonsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 16px;
}

.mobilePrintButton {
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
}

@media screen and (max-width: $screen-width-600) {
  .mobilePrintButton {
    width: 70px;
  }
}

@media only screen and (max-width: $screen-width-400) {
  .mobilePrintButtonsContainer .mobilePrintButton:nth-child(-n + 3) {
    display: none;
  }
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.5;
}

.loadingSpinner, .imageNotFoundMessage {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  color: map-get($cptColors, white);
  transform: translate(-50%, -50%);
}

.imageNotFoundMessage {
  font-size: large;
}

.hidden {
  display: none;
}
