@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import '~@oup/shared-front-end/dist/styles/themes/base/sizes';

.GradebookCard {
  margin-bottom: 1rem;

  &__card {
    border-top: 0.063rem;
  }

  &__title {
    margin-top: 1.5rem;
    font-size: 1.313rem;
    line-height: normal;
  }

  &__lastAccessed {
    margin-top: 0.75rem;
    line-height: normal;

    &__label {
      font-weight: bold;
    }

    &__date {
      font-weight: normal;
    }
  }

  &__average {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    background: map-get($primary, primary10);
    border-radius: 0.25rem;

    &__column {
      display: flex;
      width: 40%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__total {
      font-size: 1.125rem;
    }

    &__totalLabel {
      font-size: 0.875rem;
      text-transform: lowercase;
    }

    &__totalIsActivityLabel {
      padding-bottom: 0.625rem;
    }
  }

  &__table {
    width: 100%;
    margin-bottom: -1rem;
    border-collapse: collapse;

    th,
    td {
      width: 33.33%;
      font-size: 1rem;
      text-align: center;
    }

    td {
      padding: 1rem 0;
    }

    tbody {
      th {
        text-align: left;
      }
    }

    &__scoreCell {
      border-radius: 2.5rem;
    }

    td:has(&__filterHighlight) {
      background-color: $color-highlight;
    }
  }
}

.scoreMainContainer {
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: space-evenly;
  padding-right: 1rem;
}

.pendingIconContainer {
  display: none;
  width: 30%;

  svg {
    fill: map-get($hubColors, gray);
  }
}

.pendingIconExerciseContainer {
  display: none;
  width: 100%;

  svg {
    width: 25%;
    fill: map-get($hubColors, blue-color-secondary);
  }
}


.locked td,
.locked th {
  background-color: map-get($neutral, neutral-grey);
}
.lockedMessage {
  padding: $gap-3 0;
  color: map-get($base, black);
  svg {
    width: $icon-size-small;
    margin-right: $gap-1;
    fill: map-get($primary, primary90);
  }
}


@media #{$phoneMedia} {
  .pendingIconContainer {
    display: flex;
  }

  .pendingIconExerciseContainer {
    display: flex;
  }
}
