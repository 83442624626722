@use 'sass:map';
@import '../../../../../styles/constants/colors';
@import '../../../../../styles/mixins/hub/hub-button-focus';
@import '~@oup/shared-front-end/dist/styles/themes/base/sizes';

.header {
  .close {
    text-align: right;

    button {
      border: 1px solid $color-light-grey;

      span {
        color: map.get($primary-colors, primary);
      }

      svg {
        fill: map.get($primary-colors, primary);
      }

      &:hover {
        background-color: $color-very-light-grey;
      }

      &:focus {
        @include standart-focus-button;
      }
    }
  }
}

.link:focus {
  @include no-padding-button-on-focus;

  outline: none;
}

.infoContainer {
  display: flex;
  padding: 0.75rem;
  border-radius: 5px;
  background-color: map.get($secondary-colors, hub_light_grey);

  p {
    margin-left: 0.625rem;
    font-size: 0.875rem;

    span:first-of-type {
      font-weight: 600;
    }

    span:nth-of-type(2) {
      margin-left: 5px;
    }
  }

  svg {
    width: 1rem;
  }
}

.containerContent {
  display: flex;
  flex-direction: column;
  padding: $gap-4;
  gap: $gap-5;
}
