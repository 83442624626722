@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.gap-1 {
  gap: 1em;
}

.form {
  border-right: solid 1px map.get($primary, primary20);
  border-left: solid 1px map.get($primary, primary20);
}

.wrapper {
  padding: 2em;
}

.preview {
  margin-bottom: .5rem;
}

.confirmationModalWrapper {
  display: flex;
  align-items: center;
}

.confirmationModal {
  max-width: 500px !important;
}

.confirmationModal h2 {
  text-wrap: balance;
}

.preview img {
  width: 100%;
  height: auto;
  border: solid 1px map.get($primary, primary20);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 map.get($primary, primary20);
  filter: grayscale(.8);
  opacity: .5;
  transition: opacity 0.25s ease-in;
}

.checkbox {
  width: 100%;
}

.previewSelected img {
  border-color: map.get($primary, primary50);
  filter: grayscale(0);
  opacity: 1;
}

.previewWrapper {
  border-left: solid 1px map.get($primary, primary20);
  background: map.get($primary, primary10)
}

.messagePreview {
  animation: fadein .25s ease-in;
  font-size: 1.5em;
  text-wrap: balance;
}

.buttonWrapper {
  background: white;
}

.w-1\/2 {
  width: 50%;
}

.dateTimeWrapper label p {
  color: #333;
}

.systemNotificationsTable table th:nth-last-of-type(2),
.systemNotificationsTable .text-left {
  text-align: left !important;
}

.w-full {
  width: 100%;
}

.inline-block {
  display: inline-block
}

.saveButton {
  margin-top: auto;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
