@use "sass:map";
@import '../../styles/constants/colors';
@import '../../styles/constants/breakpoints';

.confirmationModal {
  z-index: 104 !important;
}

.contentWrapper {
  height: 640px;
}

.confirmationModal.popupOverSlider {
  z-index: 1000 !important;
  left: -10vw;

  @media #{$smMedia} {
    left: -40vw;
  }

  @media #{$lgMedia} {
    left: -60vw;
  }
}

.iconBg {
  background-image: url('../../../static/images/insp/icon-right-thick-16.svg');
  background-position: 99% 50%;
  background-repeat: no-repeat;
}

.dropdownHeader {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border-width: 1px;
  border-style: solid;
  border-color: map.get($background-and-border-colors, border);
  border-radius: 4px;
  box-shadow: inset 0 1px 1px $contrast-light-shadow;
  line-height: 38px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.dropdownPopup {
  text-align: left;
}

.dropdownOptions {
  max-height: 350px;
  margin: 10px 0 0;
  overflow-y: auto;
}

.dropdownOption {
  padding: 7px;
  cursor: pointer;
}

.dropdownOption:hover, .dropdownOption.highlighted, .dropdownOption[aria-selected="true"] {
  background-color: map.get($primary-light-colors, primary-light);
}
