@use "sass:map";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.closeButton {
  margin: 4px;
}

.headerContainerTitle {
  margin-left: 1rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.325rem 0 0;
  }

  .licenceInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .licencesStatus {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.closeButton.closeButton button svg {
  width: 11px;
  height: 11px;
  margin: 0;
}

.description {
  flex-grow: 1;
  padding: 2rem 1rem 2rem 0;
  text-align: left;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.title {
  min-height: 3rem;
  margin-top: 0;
}

@media (width <= 480px) {
  .bookIcon {
    display: none;
  }

  .title,
  .licenceInfoContainer,
  .productTypeLabelContainer {
    padding-left: 2rem;
  }
}

.linkedContentContainer {
  min-width: 320px;
  padding-top: 5px;
}

.content {
  display: table;
  padding: 1rem;
}

.bookInfo {
  padding-left: 2rem;
  margin-top: 3rem;
}

.bookIcon {
  width: 5rem;
  float: left;
}

.description hr {
  margin: 4px;
}

.panel {
  max-height: 332px;
  overflow-y: auto;
}

.close {
  padding-right: 6px;
  margin-top: 6px;
  float: right;
}

.imge {
  padding-left: 2rem;
}

.linkedContent {
  float: left;
}

.lozenge {
  float: inherit;
}

.helpAndSupportLink {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: map.get($secondary-colors, hub_dashboard);
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    filter: brightness(50%);
  }

  svg {
    fill: map.get($secondary-colors, hub_dashboard);
  }
}

.licencesStatus {
  flex-direction: row;
  font-weight: 400;
}

@media (max-width: $screen-width-600) {
  .headerContainerTitle .licenceInfoContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
