@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.inviteActionsSummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  .inviteActionSummaryText {
    margin: 1rem 0 0 0;

    & div {
      &:first-child {
        font-size: 21px;
      }

      &:last-child {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .buttonValidationRed {
    border-color: map-get($primary, primary0);
    background-color: map-get($validation, validation-red100);
    color: map-get($base, white100);

    svg {
      fill: map-get($base, white100);
    }

    span {
      color: map-get($base, white100);
    }

    &:hover {
      background-color: darken(map-get($validation, validation-red100), 10%);
    }

    &:focus {
      background-color: darken(map-get($validation, validation-red100), 10%);
    }

    &:disabled {
      background-color: map-get($validation, validation-red20);
    }

  }
}
