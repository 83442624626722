@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.registerOrgContainer {
  color: map.get($primary, primary100);

  .registerOrgContentContainer {
    display: flex;
    flex-direction: row;
    margin-top: $gap-6;
    gap: $gap-5;

    .helpContainer {
      max-width: 30%;
    }


    .pageHeading {
      margin-bottom: $gap-6;

      h1 {
        margin: $gap-0 $gap-0 $gap-2;
        font-size: $font-size-3xlarge;
      }

      p {
        font-size: $font-size-large;
        font-weight: $font-weight-600;
      }
    }

    @media (max-width: $screen-width-1024) {
      flex-direction: column;
      margin-top: $gap-5;

      .helpContainer {
        max-width: unset;
      }

      .pageHeading {
        margin-bottom: $gap-5;
      }
    }
  }


  .fieldContainer {
    margin-top: $gap-4;
  }

  .radioButtonContainer, .lmsIntegrationContainer {
    p {
      margin: $gap-0;
    }

    p:first-of-type {
      font-weight: $font-weight-700;
    }

    fieldset {
      flex-direction: row;
      margin-top: $gap-4;
    }
  }

  .toggleLabel {
    display: flex;
    color: map.get($primary, primary100);
    gap: $gap-2;
  }

  .validationStatus {
    transform: none;
  }


  .infoBannerContainer {
    margin-top: $gap-2;

    svg {
      align-self: flex-start;
    }

    .messageContainer {
      display: flex;
      flex-direction: column;

      a {
        margin-top: $gap-2;
      }
    }
  }

  .submitButtonContainer {
    margin-top: $gap-5;
  }

  .asideContainer {
    padding: $gap-5;
    border: 1px solid;
    border: $border-width-1 solid map.get($primary, primary20);
    border-radius: $border-radius-default;

    h3 {
      margin: $gap-0;
      font-weight: $font-weight-700;
    }

    p {
      margin: $gap-2 $gap-0;
    }
  }
}

.RadiobuttonImage {
  width: $icon-size-regular;
  height: $icon-size-regular;
}
