@import '../../../../styles/constants/colors';
@import '../../../../styles/constants/breakpoints';

.title {
  font-size: clamp(1rem, 2vw, 1.75rem);
}

.description {
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.actionsList ul {
  margin-left: 4px;

  li {
    display: list-item;
    margin-left: 15px;
  }
}

.tooltip > span {
  left: 0;
  width: max-content;
  max-width: 200px;
  transform: translateX(0);
}

.tooltip > span::after {
  left: 40px;
}

.link {
  display: inline-block;
  color: $color-primary-blue;
  vertical-align: middle;
  white-space: nowrap;

  svg {
    display: inline;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    vertical-align: middle;
  }

  &:hover {
    text-decoration: underline !important;
  }
}

.assessmentsLink a {
  font-size: clamp(1rem, 2vw, 1.25rem);

  svg {
    width: 1rem !important;
    height: 1rem !important;
    fill: currentcolor !important;
  }
}

.assessmentsLink a:not([disabled]) {
  border: 1px solid transparent;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  border-bottom-color: #2a4574;
  background: $contrast-light-blue;
  color: $color-white !important;
  text-shadow: 1px 1px 1px darken($contrast-light-blue, 5%);

  &:hover {
    background: darken($contrast-light-blue, 10%);
  }
}

.oisaBkgPattern {
  background: url("../../../../../static/images/oic/oisa-bkg-pattern.svg") top right no-repeat;

  @media screen and (0 <= width <= 972px) {
      background-image: none;
  }
}
