@import "../../styles/constants/colors";

.section {
  margin-top: 40px;

  @media (max-width: 1280px) {
    margin-top: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    &:last-child {
      margin-bottom: 60px;
    }

    li {
      line-height: 24px;
      padding: 5px 0;

      label {
        font-weight: normal;
      }

      [type="radio"]:checked, [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }

      [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 38px;
        cursor: pointer;
        display: inline-block;
        color: #212121;
      }

      [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border: 1px solid map-get($background-and-border-colors, background-light-blue);
        border-radius: 100%;
        background: $color-white;
      }

      [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: map-get($background-and-border-colors, background-light-blue);
        position: absolute;
        top: 7px;
        left: 7px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  div.info {
    i {
      padding-left: 15px;
    }
    svg {
      position: relative;
      top: 2px;
      width: 20px;
    }
  }
}

.checkboxLabel {
  margin: 0;
}
