@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.table {
  width: 100%;
  border-collapse: collapse;

  caption {
    overflow: hidden;
    font-size: 0;
  }

  thead {
    th {
      padding: 7px 20px 7px;
      border-bottom: 1px solid map-get($primary, primary20);
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }

  .borderHeader {
    display: inline-block;
    padding: 7px 12px 8px;
    font-size: 14px;
    line-height: 20px;

    @media screen {
      border: 1px solid map-get($primary, primary20);
      border-bottom-color: map-get($base, white100);
      margin: 0 0 -1px 15px;
      border-radius: 4px 4px 0 0;
    }

    label {
      margin-bottom: 0;
    }
  }

  .centeredHeader {
    text-align: center;
  }

  .headerSelectAll {
    margin-left: 4px;
  }

  // Dark borders variation
  &.darkBorders {
    .borderHeader {
      border: 1px solid map-get($typography-colors, sub-text-50);
      border-bottom-color: map-get($base, white100);
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: map-get($styleGuideColors, selected);
      }

      td {
        padding: 12px 15px;
        text-align: center;
      }
    }
  }

  th:first-of-type,
  td:first-of-type {
    width: 100%;
    text-align: left;
  }

  .forceTightWrap {
    th:first-of-type,
    td:first-of-type {
      width: 5% !important;
    }
  }

  .rowFocus {
    background: $color-table-background;
  }

  .cellPadding {
    padding: 12px 15px;
  }

  .topBorder {
    border-top: 1px solid map-get($primary, primary20) !important;

    @media print {
      border-color: $color-black;
    }

    @media screen and (max-width: 598px) {
      border-top: 1px solid map-get($primary, primary20);
    }
  }

  tr > * {
    display: none;
  }

  tr > *:first-child {
    display: table-cell;
  }

  &.persistantColumns {
    tr > * {
      display: table-cell;
    }
  }

  .selectableItem > div {
    display: flex;

    > *:first-child {
      margin-right: 1rem;
    }
  }

  .cell-ICON,
  .cell-STATUS {
    font-size: 0;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .revealButton {
    svg {
      fill: $color-primary-blue;
      transform: rotate(90deg);
      transition: transform 0.25s linear;
    }

    &.revealButtonOpen svg {
      transform: rotate(270deg);
    }
  }

  .popupRow {
    &:hover {
      background: none;
    }

    td {
      padding: 0;
      border-top: none;
    }
  }

  // Prevent date/time text from wrapping:
  time {
    white-space: nowrap;
  }

  &.leftAligned {
    th,
    td {
      text-align: left;
    }
  }

  &.centerAligned {
    th,
    td {
      text-align: center;
    }
  }

  .leftAligned {
    text-align: left !important;
  }

  .centerAligned {
    text-align: center !important;
  }

  // The compact table
  &--compact {
    thead {
      th {
        padding: 0 5px;

        &:first-of-type {
          padding: 5px;

          span {
            padding: 0;
            border: none;
            margin: 0;
          }
        }
      }
    }

    th,
    td {
      height: 43px;
      text-align: center;

      &:first-of-type {
        overflow: hidden;
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:nth-last-of-type(2) {
        width: 100px;
      }

      &:last-of-type {
        width: 50px;
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: transparent;
        }

        td {
          padding: 0 5px;
        }
      }
    }

    tr > * {
      display: table-cell;
    }

    .revealButton {
      width: 35px;
      text-align: right;
    }

    // Nested compact table
    table {
      thead {
        display: none;
      }

      tbody {
        tr:first-of-type td {
          border-top: none;
        }
      }

      .selectableItem {
        font-weight: normal;
      }

      // Third nested table
      table {
        thead {
          display: table-header-group;

          th {
            height: 15px;
            padding-top: 8px;
            border-bottom: 0;
            line-height: 15px;
          }
        }

        tbody {
          border-bottom: 1px solid map-get($primary, primary20);

          tr:nth-child(even) {
            background: $color-calendar;
          }

          tr {
            &:not(:first-of-type) td {
              border-top: 0;
            }
          }

          tr:not(.popupRow) {
            td:first-of-type::before {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .popupRow > td {
      text-align: center;
    }
  }

  .row-PROCESSING {
    td {
      opacity: 0.5;
    }

    .cell-STATUS {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 599px) {
  .table.tabletBreakpoint {
    tr > * {
      display: table-cell;
    }
  }

  .tableHeaderLabel {
    display: block !important;
  }
}

@media #{$smMedia} {
  .table {
    tr > * {
      display: table-cell;
    }

    .firstHeaderCell {
      padding: 0 20px 0 0;
    }
  }

  .tableHeaderLabel {
    display: block !important;
  }
}

.labelWrapper {
  margin-left: 15px;
}
