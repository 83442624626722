@use "sass:map";
@use "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import '../../styles/constants/colors';
@import '../../styles/constants/breakpoints';
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.AverageBanner {
  padding: 10px;
  margin: 0 -1em 1em;
  background: map.get($modern, kipper-yellow);

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__totals {
    display: flex;
    margin-top: 6px;
  }

  &__total {
    width: 50%;
    font-size: 18px;
    text-align: center;
  }

  &__totalLabel {
    font-size: 14px;
    text-transform: lowercase;
  }
}

.scoreMainContainer {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2rem;
}

.pendingIconContainer {
  display: none;
  width: 20%;

  svg {
    fill: map.get($hubColors, gray);
  }
}

@media #{$phoneMedia} {
  .pendingIconContainer {
    display: flex;
  }
}

@media #{$xsMedia} {
  .averageBannerRestyle {
    margin: sizes.$gap-2 sizes.$gap-0 sizes.$gap-4;
  }

  .titleRestyle {
    text-align: center;
  }
}
