@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import '../../styles/constants/breakpoints';
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

// Container for both the button and the dropdown:
.container {
  position: relative;
  white-space: nowrap;
}

// Dropdown outer container:
.popupOverlay {
  margin-top: 14px;

  &.hangRight {
    right: 0;
    left: auto;
  }

  &.hangLeft {
    right: auto;
    left: 0;
  }

  fieldset {
    margin: 0;
    box-shadow: 0 3px 7px 0 $contrast-light-box-shadow;
  }

  // Heading at top of dropdown:
  .overlayLabel {
    display: block;
    padding-top: 10px;
    padding-left: 16px;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

// Dropdown list-items container:
.popupContainer {
  .svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-top: 2px;
    border-radius: 5px;
    pointer-events: none;

    &.radioType {
      border-radius: 100%;
    }

    &.radioType,
    &.checkboxType {
      border: 1px solid map-get($background-and-border-colors, border-light-grey);
      fill: $color-white;
    }
  }

  li {
    position: relative;
    min-width: 230px;
    min-height: 50px;
    padding: 16px;

    &:hover,
    &:active {
      background-color: $color-button-background3;

      .svg.radioType,
      .svg.checkboxType {
        border-color: map-get($typography-colors, sub-text);
      }
    }

    // Hide the native checkmark ✔ in IE (because IE does not support appearance:none)
    ::-ms-check {
      display: none;
    }

    // This field is restyled placed in front of the label so that keyboard nav still works:
    input {
      // Make field the same size as the label so that focus looks same as Style Guide when navigating with keyboard:
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      // For iOS 10 compatibility
      border: none;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: none;
      cursor: pointer;
      outline: none;

      &[id^='control-classClassworkSearch-class-filterArchived']::before {
        position: absolute;
        width: 88%;
        height: 1px;
        margin: 0 6%;
        background-color: map-get($background-and-border-colors, border);
        content: "";
      }

      &:focus {
        @include standart-focus-button();

        border-radius: 3px;

        + label svg {
          &.radioType,
          &.checkboxType {
            border-color: map-get($primary-colors, primary);
            box-shadow: inset 0 0 0 $color-white;
          }
        }
      }
    }

    label {
      display: flex;
      margin: 0;

      span {
        margin-left: 14px;
      }
    }
  }
}

body[data-theme="dark-theme"] {
  .popupOverlay {
    margin-top: 10px;
    border-radius: 5px;
  }

  .popupContainer {
    overflow: hidden;
    border-radius: 5px;

    input {
      outline: none;
    }
  }
}

.radioButtonGroupContainer {
  gap: $gap-2;
}
