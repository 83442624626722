@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.hubListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  margin: 0.75rem 1.75rem 0 0;

  .textContainer,
  .textContainerClasswork {
    span {
      color: map.get($base, black);
      font-size: 1rem;
      font-weight: 600;
      word-break: keep-all;
    }
  }

  .textContainer:first-child,
  .textContainerClasswork:first-child {
    display: flex;
    width: 50%;
    align-items: center;
    @media (max-width: $screen-width-600) {
      width: 40%;
    }

    span {
      margin: 0 0 0 0.75rem;
    }
  }

  .textContainerClasswork:first-child {
    span {
      margin: 0;
    }
  }

  .textContainer:nth-child(2) {
    width: 10%;
    text-align: left;
  }

  .textContainer:nth-child(3) {
    width: 25%;
    text-align: left;
    white-space: normal;
    word-break: break-all;
  }

  .buttonContainer {
    display: flex;
    width: 10%;
    height: 10%;
    justify-content: center;
    text-align: right;
    transform: translateX(0.9375rem);

    svg {
      height: 1.5rem;

      path {
        fill: map.get($base, white100);
      }
    }
  }

  .containerWithNoMargin,
  .completedTest {
    span:first-child {
      margin-left: 0;
    }
  }

  .completedTest:first-child {
    width: 70%;

    +.textContainer {
      width: 15%;
      text-align: center;
    }
  }
}

@media (max-width: $screen-width-840) {
  .hubListHeader {
    padding: 0;
    gap: 1rem;

    .buttonContainer {
      width: 20%;
    }

    .textContainer:nth-child(2) {
      text-align: center;
    }

    .textContainer:nth-child(3) {
      width: 30%;
    }
  }
}

@media (max-width: $screen-width-600) {
  .hubListHeader {
    .buttonContainer {
      width: 25%;
      justify-content: center;
    }

    .textContainer:nth-child(2) {
      text-align: center;
    }

    .textContainer:nth-child(3) {
      width: 17%;
    }
  }
}

@media (max-width: $screen-width-480) {
  .hubListHeader {
    .buttonContainer {
      padding-right: 5rem;
    }

    .textContainer {
      display: none;
    }

    .textContainerClasswork {
      span {
        font-size: 0.875rem;
      }
    }
  }
}
