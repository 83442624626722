@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";

div.accountWidget {
  top: 100%;
  right: 1.25rem;
  overflow: hidden auto;
  margin-top: 0;
  line-height: 1rem;
  white-space: nowrap;

  @media #{$smMedia} {
    right: 0;
  }

  @media #{$xsMedia} {
    right: 1rem;
    max-width: calc(100% - 2rem);
  }

  // Center the popup arrow on the button
  &::before,
  &::after {
    right: 4px;
  }

  button {
    margin: 10px;

      &.itemLink{
      margin: 0 !important;

      div {
        margin: 0;
      }
    }
  }

  .myDetails,
  .myOrganizations {
    border-top: 1px solid $color-borders;

    h3 {
      display: flex;
      flex-grow: 1;
      padding: 10px 15px;
      padding: 16px;
      margin: 0;
      font-size: 0.875rem;
    }
  }

  .topBanner {
    min-width: 383px;
    border-top: 1px solid $color-borders;

    @media #{$xsMedia} {
      width: auto;
    }
  }

  .myDetails > div {
    padding: 0 16px 12px;
  }

  .myDetails {
    .editAccountButton {
      border: 1px solid $color-borders;
      color: $color-primary-blue;
    }

    .editAccountButton.modalBasedEditAccountButton,
    a.editAccountButton {
      height: 36px;
      padding: 0 16px;
      border-radius: 18px;
      margin-bottom: 12px;
      margin-left: 60px;
      box-shadow: none;
    }

    button.exitImpersonationButton {
      width: calc(100% - 2rem);
      padding: 1rem;
      border: none;
      border-radius: $border-radius-default;
      background-color: map.get($heritage, ruskin-red100);
      color: map.get($base, white100);

      &:focus {
        background-color: map.get($heritage, ruskin-red100);
        box-shadow: $box-shadow-focus;
        outline: 0;
      }

      &:hover {
        background-color: map.get($heritage, ruskin-red80);
      }
    }
  }

  .userDetailsThumbnail {
    > div {
      margin-right: 6px;
      background-color: map.get($styleGuideColors, primary-dark);
    }

    + div {
      overflow-x: hidden;
    }
  }

  .impersonationDetailsThumbnail {
    svg {
      border-radius: $border-radius-round;
      background-color: map.get($heritage, ruskin-red100);
      fill: "white";
    }
  }

  .myAccount {
    h2 {
      padding: 10px 15px;
      padding-bottom: 5px;
      margin: 0;
      color: $color-text;
      font-size: 1.25em;
      font-weight: 600;
    }

    p {
      padding: 10px 15px;
      margin: 0;
    }
  }

  .myOrgLink,
  .itemLink {
    display: flex;
    width: 100%;
    height: 58px;
    align-items: center;
    padding: 16px;
    color: $color-primary-blue;
    text-decoration: none;
  }

  .myOrgLink {
    outline-offset: -5px;
  }

  .itemLink {
    height: 40px;
    border-top: 1px solid $color-borders;
  }

  .myOrgLink:hover,
  .itemLink:hover,
  .myDetails .editAccountButton:hover {
    background-color: map.get($styleGuideColors, selected);
    color: map.get($styleGuideColors, primary-dark);
  }

  .myDetails .editAccountButton:focus {
    outline: auto;
  }

  .smallSubText {
    overflow: hidden;
    margin-top: 0;
    color: $color-sub-text;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  .dropdown {
    padding: 1rem;
    margin: 5px 15px 10px;
    margin-bottom: 1rem;
    background-color: $color-very-light-grey;

    label {
      margin: 0;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }
  }

  nav.profileMenu {
    section.myDetails {
      button.exitImpersonationButton {
        height: auto;
        margin: 0 1rem 1rem;
      }
    }
  }
}

@media #{$smMedia} {
  div.accountWidget {
    width: auto;

    &::before,
    &::after {
      right: 24px;
    }
  }
}

body[data-theme="hub-theme"] {
  div.accountWidget {
    top: 100%;
    margin: 4px 0 0;

    &::before,
    &::after {
      right: 16px;
    }

    .myDetails {
      button.editAccountButton {
        height: 36px;
        padding: 0 16px;
        border-radius: 18px;
        margin-bottom: 12px;
        margin-left: 60px;
        box-shadow: none;

        &:focus {
          border-color: map.get($primary-colors, primary);
          background-color: map.get($styleGuideColors, selected);
          box-shadow: 0 0 0 1px $color-white, inset 0 0 0 1px map.get($primary-colors, primary);
          box-shadow:
            0 0 0 1px $color-white,
            inset 0 0 0 1px map.get($primary-colors, primary);
          color: map.get($styleGuideColors, primary-dark);
        }
      }

      button.existImpersonationButton {
        width: calc(100% - 2rem);
        height: auto;
        padding: 1rem;
        border: none;
        border-radius: $border-radius-default;
        margin: 0 1rem 1rem;
        background-color: map.get($heritage, ruskin-red100);
        color: map.get($base, white100);

        &:focus {
          background-color: map.get($heritage, ruskin-red100);
          box-shadow: $box-shadow-focus;
          outline: 0;
        }

        &:hover {
          background-color: map.get($heritage, ruskin-red80);
        }
      }
    }

    .userDetailsThumbnail {
      margin-right: 16px;

      > div {
        margin-right: 0;
      }
    }

    a.itemLink {
      height: initial;
    }

    @media #{$smMedia} {
      margin: 7px 0 0;

      &::before,
      &::after {
        right: 11px;
      }
    }

    @media #{$phoneMedia} {
      &::before,
      &::after {
        right: 8px;
      }
    }
  }
}
