@import "../../styles/constants/breakpoints.scss";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";

.RecordListingControls {
  align-items: center;
  padding: 1rem 0.7rem 0;
  background: map-get($primary, primary10);

  .centerSpacingOptions {
    text-align: right !important;

    li {
      margin: 0;
    }
  }

  @media #{$mdMedia} {
    .centerSpacingOptions {
      text-align: center !important;
    }
  }
}

body[data-theme="hub-theme"] {
  color: map-get($primary, primary100);

  .grid {
    padding-right: 0;
    padding-left: 0;
  }

  .RecordListingControls {
    background: map-get($primary, primary10);
    font-size: 0.95rem;

    &.assessment {
      display: flex;
      justify-content: space-between;
    }

    .gin-bot2 {
      white-space: nowrap;
    }
  }
}
